import { OptionsIcon } from 'assets/icons/OptionsIcon';
import axios from 'axios';
import { ModalDelete } from 'components/ModalDelete/ModalDelete';
import { NotificacionI } from 'interfaces/Notificacion';
import { report } from 'process';
import { useState } from 'react';
import { Badge, Button, Dropdown, Spinner } from 'react-bootstrap';
import { ENDPOINTS } from 'services/endpoints';
import { generateReport, getReporte } from 'services/reportes';
import { sendRequest } from 'services/request/request.service';
import { servicesUtils } from 'services/utils/services.utils';
import { formatDate } from 'utils/formatUtils';
interface Props {
    notificacion: NotificacionI
    handleShowDetailModal: (id: number | string) => void,
    handleDelete: (id: number | string) => void,
    handleEdit: (notificacion: NotificacionI) => void,
    startLoading: () => void,
    stopLoading: () => void
}

export const NotificacionesRow = ({ notificacion, handleShowDetailModal, handleDelete, handleEdit, startLoading, stopLoading
}: Props) => {
    const [isLoading, setIsLoading] = useState(false)
    const [reportState, setReportState] = useState<String | null>(notificacion.reports.length > 0 ? notificacion.reports[0].reportState.name : "NO GENERADO");

    const generarReporte = async (notificationId: number, reportTypeId: number) => {
        try {
            //startLoading();

            setReportState("PROCESANDO")
            
            const response = await generateReport(notificationId, reportTypeId);

            console.log("Reporte generado con éxito:", response);



        } catch (err) {
            console.error("Hubo un problema al descargar el reporte", err);
            setReportState("Error")
        } finally {
            //stopLoading();
        }
    };


    const downloadExcel = async (id: string) => {
        try {
            startLoading();
            const response = await getReporte(id);

            const contentDisposition = response.headers.get('Content-Disposition');

            let filename = 'reporte-notificacion.xlsx';
            if (contentDisposition && contentDisposition.includes('filename=')) {
                filename = contentDisposition
                    .split('filename=')[1]
                    .replace(/['"]/g, '');
            }
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
        } catch (err) {
            console.error("Hubo un problema al descargar el excel", err);
        } finally {
            stopLoading();
        }
    };


    const [showDeleteModal, setShowDeleteModal] = useState(false);


    const handleShowDeleteModal = () => {
        setShowDeleteModal(true);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const handleConfirmDelete = () => {
        handleDelete(notificacion.id);
        handleCloseDeleteModal();
    };

    const handleReportGeneralGenerate = (id: any) => {
        generarReporte(id, 1);
    };

    const handleReportVistoslGenerate = (id: any) => {
        generarReporte(id, 2);
    };

    const handleReportDownload = (id: any) => {
        downloadExcel(id);
    };
    return (
        <>
            <tr className='table-row' key={`NotificacionesTable-${notificacion.title}`}>
                <td>{notificacion.title}</td>
                <td>{notificacion.notificationType?.name}</td>
                <td>{formatDate.stringDate(notificacion.sendAt)}</td>
                <td>
                    <Badge bg={"none"}
                        style={{
                            color: notificacion.notificationState?.color,
                            backgroundColor: notificacion.notificationState?.backgroundColor,
                            borderColor: notificacion.notificationState?.borderColor,
                        }}>
                        {notificacion.notificationState?.name}
                    </Badge>
                </td>
                <td>
                    {notificacion.reports[0]?.reportState.name != "FINALIZADO" || reportState =='PROCESANDO' ? reportState : <Button size="sm" onClick={() => handleReportDownload(notificacion.id)}>
                        Descargar {notificacion.reports[0]?.reportType.name.toLowerCase()}
                    </Button>}
                </td>
                <td>
                    <Dropdown className="acciones-dropdown | d-flex justify-content-center">
                        <Dropdown.Toggle>
                            <OptionsIcon />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleShowDetailModal(notificacion.id)}>Ver notificacion</Dropdown.Item>
                            {notificacion.isEditable && <Dropdown.Item onClick={() => handleEdit(notificacion)}>Editar</Dropdown.Item>}
                            {notificacion.isEditable && <Dropdown.Item onClick={handleShowDeleteModal}>Eliminar</Dropdown.Item>}
                            {(notificacion.notificationState.id == 2) && <Dropdown.Item onClick={() => handleReportGeneralGenerate(notificacion.id)}>Reporte General</Dropdown.Item>}
                            {(notificacion.notificationState.id == 2) && <Dropdown.Item onClick={() => handleReportVistoslGenerate(notificacion.id)}>Reporte Solo Vistos</Dropdown.Item>}
                        </Dropdown.Menu>
                    </Dropdown>
                </td>
            </tr>

            {isLoading && (
                <div className="overlay">
                    <Spinner animation="border" role="status" className="spinner-center" />
                </div>
            )}

            <ModalDelete
                showModal={showDeleteModal}
                handleCloseModal={handleCloseDeleteModal}
                handleConfirmDelete={handleConfirmDelete}
                isUserDelete={false}
            />
        </>
    )
}
