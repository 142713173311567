import { Pagination } from 'components/Pagination/Pagination'
import { useEffect, useState } from 'react'
import { Button, Card, Spinner } from 'react-bootstrap'
import { UsuariosTable } from './UsuariosTable/UsuariosTable'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store/store'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { UserModal } from './UserModal/UserModal'
import { deleteUsuario, getUsuarios } from 'services/usuarios'
import { UsuarioI } from 'interfaces/Usuario'
import { Select } from 'components/Select/Select'
import { fetchAreas, fetchRoles } from 'store/slices/appSlice'
import { useFormik } from 'formik'  
import { DEFAULT_LIMIT_PAGINATION } from 'constants/appConstants'
import { LupaIcon } from 'assets/icons/LupaIcon'
import { Input } from 'components/Input/Input'
import { showErrorToast, showSuccessToast } from 'components/CustomAlerts/CustomToast'
import { useAlertContext } from 'context/AlertContext'
import { SuccessIcon } from 'assets/icons/SuccessIcon';
import { ErrorIcon } from 'assets/icons/ErrorIcon'


export const Usuarios = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [elementos, setElementos] = useState<UsuarioI[]>([])
    const [cantidad, setCantidad] = useState<number>(0)
    const [page, setPage] = useState<number>(1)
    const [limit, setLimit] = useState<number>()
    const { alerts, removeAlert, addAlert } = useAlertContext();

    const initialValues = {
        roles: "",
        email: "",
    }

    const {
        values,
        handleChange,
        resetForm, 
    } = useFormik({
        initialValues,
        onSubmit: () => { },
    });

    const dispatch: AppDispatch = useDispatch();
    const { roles, loadingAppData } = useSelector((st: RootState) => st.appData)
    const { role, area } = useSelector((st: RootState) => st.authData);

    const searchUsuarios = async (params?: { filtros?: { [key: string]: any } }) => {
        const defaultFilters =  (role === 2)? { area_id: area} : {}
        try {
            setIsLoading(true)
            console.log({ params, role, area })
            const response = await getUsuarios({...params?.filtros, ...defaultFilters})
            console.log("CANTIDAD NUEVA", response)
            setElementos(response.elementos)
            setCantidad(response.cantidad)
            setPage(response.page)
            setLimit(params?.filtros?.limit ?? DEFAULT_LIMIT_PAGINATION)
        } catch (err) {
            showErrorToast("Error al buscar usuarios");
        } finally {
            setIsLoading(false)
        }
    }

    const handleDelete = async (id: string | number) => {
        try {
            await deleteUsuario(id);
            addAlert({ icon: <SuccessIcon/> , type: 'success', boldMessage: '¡El usuario fue eliminado con éxito!'});
            // showSuccessToast("¡El usuario fue eliminado con éxito!");
            searchUsuarios({
                filtros: {
                    ...values,
                    page: 1,
                    limit,
                }
            })
        } catch (error) {
            addAlert({ icon: <ErrorIcon/> , type: 'error', boldMessage: 'Error al eliminar el usuario'});
            // showErrorToast("Error al eliminar el usuario");
        }
    }

    const handleChangePagination = (newFiltros: { [key: string]: (string | number) }) => {
        searchUsuarios({ filtros: { ...values, ...newFiltros } })
    }

    const handleClearFilters = () => {
        resetForm(); 
        searchUsuarios(); 
    }

    const [showUserModal, setShowUserModal] = useState(false)

    const handleShowUserModal = () => {
        setShowUserModal(true)
    }

    const handleCloseModal = () => {
        setShowUserModal(false)
    }
    const handleChangeWithResetPage = (pageOne:any) => {
        setPage(1);
        handleChange(pageOne);
    };

    useEffect(() => {
        searchUsuarios({ filtros: { ...values, limit, page } })
    }, [values])

    useEffect(() => {
        if (!roles) dispatch(fetchRoles());
    }, [])

    return (
        <section>
            <h1 className="section-title" onClick={() => console.log({ limit })}>Gestión de usuarios</h1>
            <h2 className="section-subtitle mt-2">Buscá y agregá usuarios para que puedan enviar notificaciones o <br /> cargar novedades.</h2>
            
            <div>
                <div className="alert-container">
                    {alerts.map((alert, index) => (
                        <div key={index} className={`alert ${alert.type}`}>
                            <div>
                                {alert.icon}
                            </div>
                            <span className="alert-text">
                                <strong>{alert.boldMessage}</strong>
                                {alert.normalMessage && <span> {alert.normalMessage}</span>}
                            </span>            
                            {/* <button onClick={() => removeAlert(index)}>Cerrar</button> */}
                        </div>
                    ))}
                </div>
            </div>

            <div className='d-flex justify-content-between mt-4 mb-3'>
                <div className='d-flex column-gap-3'>
                    <Input
                        size="sm"
                        id="email"
                        onChange={handleChangeWithResetPage}
                        placeholder='Buscar usuario'
                        values={values}
                        startIcon={<LupaIcon />}
                    />
                    <Select
                        id="role_id"
                        optionsFormatConfig={{ value: "id", label: "name" }}
                        options={roles ?? []}
                        onChange={handleChangeWithResetPage}
                        isLoading={loadingAppData.roles}
                        placeholder="Rol"
                        width="150px"
                        values={values}
                    />
                    <Button size="sm" variant="secondary" className='clear-filters-button' onClick={handleClearFilters}>Limpiar filtros</Button>
                </div>


                <div>
                    <Button size="lg" onClick={handleShowUserModal}>Nuevo Usuario</Button>
                </div>
                <UserModal 
                    refreshPage={() => searchUsuarios({filtros:{
                        ...values,
                        page: 1,
                    }})}
                    handleCloseModal={handleCloseModal}
                    showModal={showUserModal}
                    isEditMode={false}
                />
            </div>

            {isLoading && <div className="d-flex justify-content-center w-full"><Spinner /></div>}

            {!isLoading && elementos && cantidad != undefined && cantidad > 0 &&
                <>
                    <UsuariosTable
                        elementos={elementos}
                        handleDelete={handleDelete}
                        refreshPage={() => searchUsuarios({
                            filtros: {
                                ...values,
                                page: 1,
                            }
                        })}
                    />
                    <Pagination
                        totalItems={cantidad}
                        currentPage={page}
                        currentLimit={limit}
                        onChangePage={handleChangePagination}
                        showPaginationOnlyOnMoreThanPages={1}
                    />
                </>
            }

            {!isLoading && cantidad == 0 &&
                <UsuariosTable
                elementos={elementos}
                handleDelete={handleDelete}
                refreshPage={() => searchUsuarios({
                    filtros: {
                        ...values,
                        page: 1,
                    }
                })}/>
            }

        </section>
    )
}
