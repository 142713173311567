import { ENDPOINTS } from "./endpoints"
import { sendRequest } from "./request/request.service"
import { servicesUtils } from "./utils/services.utils"

export const getReporte = async (id: number | string) => {

    const elemento = await sendRequest<any>({
        method: "GET_FILE",
        uri: ENDPOINTS.REPORTS_GET + id,
        params: servicesUtils.removeNullParamsInFilters({}),
        
    })

    return elemento 
}

export const generateReport = async (notificationId: number, reportTypeId: number) => {
    const createReportDto = {
      notificationId, 
      reportTypeId,   
    };
  
    return await sendRequest<any>({
      method: "PUT",
      uri: ENDPOINTS.REPORTS_GENERATE,
      params: createReportDto,
    });
  };



