import { info } from "console";
import { format } from "date-fns";
import { AccionesNotificacionI } from "interfaces/AccionesNotificacion";
import { AnioNivelI } from "interfaces/AnioNivel";
import { AreaI } from "interfaces/Area";
import { EscuelaI } from "interfaces/Escuela";
import { EstadoNotificacionI } from "interfaces/EstadoNotificacion";
import { NivelEscuelaI } from "interfaces/NivelEscuela";
import { NotificacionI } from "interfaces/Notificacion";
import { ReporteNotificacionI } from "interfaces/ReporteNotificacionI";
import { TipoNotificacionI } from "interfaces/TipoNotificacion";
import { MinUsuarioI } from "interfaces/Usuario";

export interface infoFetchGetNotificacionI {
    id: number | string;
    title: string;
    body: string;

    areaId: number | string | null;
    notificationStateId: number | string;
    notificationTypeId: number | string;
    schoolLevelId: number | string;

    area: AreaI | null;
    notificationState: EstadoNotificacionI;
    notificationType: TipoNotificacionI;
    schoolLevel: NivelEscuelaI;
    school: EscuelaI[]
    schoolYear: AnioNivelI[];
    usersSend: MinUsuarioI[];

    createdAt: Date;
    sendAt: Date;

    isProgrammed: boolean;
    isEditable: boolean;
    hasReport:boolean;
    reports:ReporteNotificacionI[];

}

class Notificacion implements NotificacionI {
    id: number | string;
    title: string;
    body: string;

    areaId: number | string | null;
    notificationStateId: number | string;
    notificationTypeId: number | string;
    schoolLevelId: number | string;

    area: AreaI | null;
    notificationState: EstadoNotificacionI;
    notificationType: TipoNotificacionI;
    schoolLevel: NivelEscuelaI;
    schools: EscuelaI[]
    schoolYears: AnioNivelI[];
    usersSend: MinUsuarioI[];

    createdAt: Date;
    sendAt: Date;

    isProgrammed: boolean;
    isEditable: boolean;
    hasReport:boolean;

    reports:ReporteNotificacionI[];

    constructor(infoFetch: infoFetchGetNotificacionI) {
        this.id = infoFetch.id;
        this.title = infoFetch.title;
        this.body = infoFetch.body;

        this.areaId = infoFetch.areaId;
        this.notificationStateId = infoFetch.notificationStateId;
        this.notificationTypeId = infoFetch.notificationTypeId;
        this.schoolLevelId = infoFetch.schoolLevelId;

        this.area = infoFetch.area;
        this.notificationState = infoFetch.notificationState;
        this.notificationType = infoFetch.notificationType;
        this.schoolLevel = infoFetch.schoolLevel;

        this.schools = infoFetch.school;
        this.schoolYears = infoFetch.schoolYear;

        this.createdAt = new Date(infoFetch.createdAt);
        this.sendAt = new Date(infoFetch.sendAt);
        this.usersSend = infoFetch.usersSend;

        this.isProgrammed = infoFetch.isProgrammed;
        this.isEditable = infoFetch.isEditable;
        this.hasReport = infoFetch.hasReport;
        this.reports = infoFetch.reports

    }
}

export const makeNotificacion = (infoFetch: infoFetchGetNotificacionI) => {
    return new Notificacion(infoFetch)
}