import { Pagination } from 'components/Pagination/Pagination'
import { Select } from 'components/Select/Select'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { Button, Card, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { PRIVATE_ROUTE } from 'routes/privateRoutes'
import { fetchEstadosNovedad, fetchNivelesEscuela } from 'store/slices/appSlice'
import { AppDispatch, RootState } from 'store/store'
import { DEFAULT_LIMIT_PAGINATION } from 'constants/appConstants'
import { Input } from 'components/Input/Input'
import { LupaIcon } from 'assets/icons/LupaIcon'
import { showErrorToast,showSuccessToast } from 'components/CustomAlerts/CustomToast'
import { NovedadesTable } from './NovedadesTable/NovedadesTable'
import { deleteNovedad, getNovedades } from 'services/novedades'
import { NovedadI } from 'interfaces/Novedad'
import { useAlertContext } from 'context/AlertContext'
import { SuccessIcon } from 'assets/icons/SuccessIcon';
import { ErrorIcon } from 'assets/icons/ErrorIcon'


export const Novedades = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [elementos, setElementos] = useState<NovedadI[]>()
    const [cantidad, setCantidad] = useState<number>()
    const [page, setPage] = useState<number>()
    const [limit, setLimit] = useState<number>()
    const { alerts, removeAlert, addAlert } = useAlertContext();
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const { estadosNovedad, nivelesEscuela, loadingAppData } = useSelector((st: RootState) => st.appData)

    const initialValues = {
        nivelesEscuela: "",
        estadosNovedad: "",
        nivel_id: "",
    }

    const {
        values,
        handleChange,
        resetForm,
    } = useFormik({
        initialValues,
        onSubmit: () => { },
    });

    const { role, area } = useSelector((st: RootState) => st.authData);

    const searchNovedades = async (params?: { filtros?: { [key: string]: any } }) => {
        const adminFilter =  (role === 2)? { area_id: area} : {}
        const notifierFilter =  (role === 3)? { area_id: area} : {}
        try {
            setIsLoading(true)
            console.log({ params, limit })
            const response = await getNovedades({...params?.filtros, ...adminFilter, ...notifierFilter})
            
            setElementos(response.elementos)
            setCantidad(response.cantidad)
            setPage(response.page)
            setLimit(params?.filtros?.limit ?? DEFAULT_LIMIT_PAGINATION)
        } catch (err) {
            addAlert({ icon: <ErrorIcon/> , type: 'error', boldMessage: '¡No fue posible cargar las novedades!'});
            // showErrorToast("No fue posible cargar las novedades");
        } finally {
            setIsLoading(false)
        }
    }
    const handleEdit = (novedad: NovedadI) => {
        navigate(PRIVATE_ROUTE.NOVEDADES_EDITAR + "/" + novedad.id)
    }

    const handleDelete = async (id: string | number) => {
        try {
            await deleteNovedad(id);
            addAlert({ icon: <SuccessIcon/> , type: 'success', boldMessage: '¡La novedad fue eliminada con éxito!'});
            // showSuccessToast("¡La novedad fue eliminada con éxito!");
            searchNovedades({
                filtros: {
                    ...values,
                    page: 1,
                    limit,
                }
            })
        } catch (error) {
            addAlert({ icon: <ErrorIcon/> , type: 'error', boldMessage: 'Error eliminando la novedad'});
            // showErrorToast("Error eliminando la novedad");
        }
    }

    const handleChangePagination = (newFiltros: { [key: string]: (string | number) }) => {
        searchNovedades({ filtros: { ...values, ...newFiltros } })
    }

    const handleClearFilters = () => {
        resetForm(); 
        searchNovedades(); 
    }
    const handleClickCrear = () => {
        navigate(PRIVATE_ROUTE.NOVEDADES_CREAR)
    }
    const handleChangeWithResetPage = (pageOne:any) => {
        setPage(1);
        handleChange(pageOne);
    };


    useEffect(() => {
        searchNovedades({ filtros: { ...values, limit, page } })
        console.log(values);
        
    }, [values])

    useEffect(() => {
        if (!estadosNovedad) dispatch(fetchEstadosNovedad());
        if (!nivelesEscuela) dispatch(fetchNivelesEscuela());
    }, [])

    return (
        <section>
            <h1 className="section-title" onClick={() => console.log({ limit })}>Novedades</h1>

            <h2 className="section-subtitle mt-2">Publicá una nueva novedad y gestioná las novedades publicadas.</h2>

            <div>
                <div className="alert-container">
                    {alerts.map((alert, index) => (
                        <div key={index} className={`alert ${alert.type}`}>
                            <div>
                                {alert.icon}
                            </div>
                            <span className="alert-text">
                                <strong>{alert.boldMessage}</strong>
                                {alert.normalMessage && <span> {alert.normalMessage}</span>}
                            </span>            
                            {/* <button onClick={() => removeAlert(index)}>Cerrar</button> */}
                        </div>
                    ))}
                </div>
            </div>

            <div className='d-flex justify-content-between mt-4 mb-3'>
                <div className='d-flex column-gap-3'>
                    <Input
                        size="sm"
                        id="title"
                        onChange={handleChangeWithResetPage}
                        placeholder='Buscar novedad'
                        values={values}
                        startIcon={<LupaIcon />}
                    />
                        <Select
                        id="estado_id"
                        values={values}
                        onChange={handleChangeWithResetPage}
                        optionsFormatConfig={{ value: "id", label: "name" }}
                        options={estadosNovedad ?? []}
                        isLoading={loadingAppData.estadosNovedad}
                        placeholder="Estado"
                        width="145px"
                    />
                    <Select
                        id="nivel_id"
                        values={values}
                        onChange={handleChangeWithResetPage}
                        optionsFormatConfig={{ value: "id", label: "name" }}
                        options={nivelesEscuela ?? []}
                        isLoading={loadingAppData.nivelesEscuela}
                        placeholder="Nivel"
                        width="150px"
                    />

                    <Button size="sm" variant="secondary" className='clear-filters-button' onClick={handleClearFilters}>Limpiar filtros</Button>
                </div>
                <Button size="lg" onClick={handleClickCrear}>Nueva novedad</Button>
            </div>

            {isLoading && <div className="d-flex justify-content-center w-full"><Spinner /></div>}

            {!isLoading && elementos && cantidad != undefined && cantidad > 0 &&
                <>
                    <NovedadesTable
                        elementos={elementos}
                        handleDelete={handleDelete}
                        handleEdit={handleEdit}
                    />
                    <Pagination
                        totalItems={cantidad}
                        currentPage={page}
                        currentLimit={limit}
                        onChangePage={handleChangePagination}
                        showPaginationOnlyOnMoreThanPages={1}
                    />
                </>
            }

            {!isLoading && cantidad == 0 &&
                <Card className="mt-2">
                    No hay novedades que coincidan con la busqueda
                </Card>
            }

        </section>
    )
}
