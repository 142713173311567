import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import "./../styles/components/alerts.css";

interface Alert {
  type: 'success' | 'error';
  boldMessage: string; 
  normalMessage?: string; 
  icon: React.ReactNode;
}

interface AlertContextProps {
  alerts: Alert[];
  addAlert: (alert: Alert) => void;
  removeAlert: (index: number) => void;
  clearAlerts: () => void; 
} 

const AlertContext = createContext<AlertContextProps | undefined>(undefined);

export const useAlertContext = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error('useAlertContext must be used within an AlertProvider');
  }
  return context;
};

export const AlertProvider = ({ children }: { children: ReactNode }) => {
  const [alerts, setAlerts] = useState<Alert[]>([]);

  const addAlert = (alert: Alert) => {
    setAlerts((prev) => [...prev, alert]);
  };

  const removeAlert = (index: number) => {
    setAlerts((prevAlerts) => prevAlerts.filter((alert, currentIndex) => currentIndex !== index));
  };

  const clearAlerts = () => {
    setAlerts([]);
  };

  useEffect(() => {
    const timers = alerts.map((currentIndex, index) =>
      setTimeout(() => removeAlert(index), 5000)
    );
    return () => {
      timers.forEach(timer => clearTimeout(timer));
    };
  }, [alerts]);

  return (
    <AlertContext.Provider value={{ alerts, addAlert, removeAlert, clearAlerts }}>
      {children}
    </AlertContext.Provider>
  );
};
