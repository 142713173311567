import { NotificacionI } from "interfaces/Notificacion";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import "./home.css"
import { MetricsChart } from "components/Chart/MetricsChart";
import { getMetricas, getMetricasAll } from "services/metricas";
import { showErrorToast } from "components/CustomAlerts/CustomToast";
import { MetricasI } from "interfaces/Metricas";
import { EyeTemporaryIcon } from "assets/icons/EyeTemporaryIcon";
import { DetailModalNews } from "screens/Novedades/components/detailModalNews";
import { DetailModal } from "screens/Notificaciones/components/DetailModal/DetailModal";
import { useAlertContext } from 'context/AlertContext'
import { SuccessIcon } from 'assets/icons/SuccessIcon';
import { ErrorIcon } from 'assets/icons/ErrorIcon'


const Home = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [metricas, setMetricas] = useState<MetricasI>()
    const dispatch: AppDispatch = useDispatch();
    const auth = useSelector((state: RootState) => state.authData);
    const [showModalNovedades, setShowModalNovedades] = useState(false);
    const [showModalNotificaciones, setShowModalNotificaciones] = useState(false); 
    const [selectedNovedadId, setSelectedNovedadId] = useState<number | null>(null); 
    const [selectedNotificacionId, setSelectedNotificacionId] = useState<number | null>(null); 
    const { loadingAppData } = useSelector((st: RootState) => st.appData)
    const currentMonth = new Date().toLocaleString('es-ES', { month: 'long' });
    const { alerts, removeAlert, addAlert } = useAlertContext();


    const openNovedadModal = () => {
        if (metricas?.ultimaNovedad?.id) {
            const novedadId = Number(metricas.ultimaNovedad.id); 
            setSelectedNovedadId(novedadId);
            setShowModalNovedades(true);
        } else {
            addAlert({ icon: <ErrorIcon/> , type: 'error', boldMessage: 'No hay novedades disponibles.'});
            // showErrorToast("No hay novedades disponibles.");
        }
    };

    const closeModalNovedades = () => {
        setShowModalNovedades(false);
    };

    const openNotificacionModal = () => {
        if (metricas?.ultimaNotificacion?.id) {
            const notificacionId = Number(metricas.ultimaNotificacion?.id); 
            setSelectedNotificacionId(notificacionId);
            setShowModalNotificaciones(true);
        } else {
            addAlert({ icon: <ErrorIcon/> , type: 'error', boldMessage: 'No hay notificaciones disponibles.'});
            // showErrorToast("No hay notificaciones disponibles.");
        }
    };

    const closeModalNotificaciones = () => {
        setShowModalNotificaciones(false);
    };

    const searchMetricas = async (params?: { filtros?: { [key: string]: any } }) => {
        try {
            setIsLoading(true)
            const response = await getMetricasAll()
            console.log(response)
            setMetricas(response)

        } catch (err) {
            addAlert({ icon: <ErrorIcon/> , type: 'error', boldMessage: 'No fue posible cargar los datos'});
            // showErrorToast("No fue posible cargar los datos");
        } finally {
            setIsLoading(false)
        }
    }

    const truncateText = (text:string, maxLength:number) => {
        if (text.length > maxLength) {
          return text.substring(0, maxLength) + '...';
        }
        return text;
      };

    const notificaciones = metricas?.historialTotal.notificacionesEnviadas ?? 0;
    const novedades = metricas?.historialTotal.novedadesPublicadas ?? 0;
    const total = notificaciones + novedades;
    const notificacionesPorcentaje = total === 0 && !metricas ? " " : Math.round((notificaciones / total) * 100);
    const novedadesPorcentaje = total === 0 && !metricas ? " " : Math.round((novedades / total) * 100);

    useEffect(() => {
        searchMetricas();
    }, []);

    return (
        <section>
            <h1 className="section-title">¡Hola, {auth.name}!</h1>
            <h2 className="section-subtitle mt-2">Visualizá las notificaciones y novedades de la aplicación <br /> miEscuela Familias.</h2>

            <div className='home-container'>
                <div className="home-cards-container">
                    <div className='home-chart-container'>
                        <div>
                            <p className='home-cards-titles mx-3'>Histórico</p>
                            <hr className='profile-cards-divider' />
                            <div className='d-flex justify-content-between align-items-center | home-cards-titles-container'>
                                <p className='profile-date'>Notificaciones enviadas</p>
                                <span className='profile-notifications-number'>{metricas?.historialTotal.notificacionesEnviadas ?? "-"}</span>
                            </div>
                            <div className='d-flex justify-content-between align-items-center | home-cards-titles-container'>
                                <p className='profile-date'>Novedades publicadas</p>
                                <span className='profile-notifications-number'>{metricas?.historialTotal.novedadesPublicadas ?? "-"}</span>
                            </div>
                        </div>
                        <div className="chart-container mt-2">
                        <MetricsChart notificaciones={notificaciones} novedades={novedades} />
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-3 | home-cards-titles-container">
                        <p className='profile-date'>Notificaciones {notificacionesPorcentaje}%</p>
                        <p className='profile-date'>Novedades {novedadesPorcentaje}%</p>
                        </div>
                    </div>
                </div>

                <div className="home-cards-container">
                    <div className='home-cards'>
                        <p className='home-cards-titles mx-3'>Historial notificaciones {currentMonth}</p>
                        <hr className='profile-cards-divider' />
                        <div className='d-flex justify-content-between align-items-center | home-cards-titles-container'>
                            <p className='profile-date'>Notificaciones enviadas</p>
                            <span className='profile-notifications-number'>{metricas?.historialNotificacionesMensual.enviadas ?? "-"}</span>
                        </div>
                        <div className='d-flex justify-content-between align-items-center | home-cards-titles-container'>
                            <p className='profile-date'>Usuarios que la leyeron</p>
                            <span className='profile-notifications-number'>{metricas?.historialNotificacionesMensual.lecturas ?? "-"}</span>
                            {/* <span className='profile-notifications-number'>-</span> */}
                        </div>
                    </div>
                    <div className='home-cards'>
                        <p className='home-cards-titles mx-3'>Historial novedades {currentMonth}</p>
                        <hr className='profile-cards-divider' />
                        <div className='d-flex justify-content-between align-items-center | home-cards-titles-container'>
                            <p className='profile-date'>Novedades publicadas</p>
                            <span className='profile-notifications-number'>{metricas?.historialNovedadesMensual.publicadas ?? "-"}</span>
                        </div>
                        <div className='d-flex justify-content-between align-items-center | home-cards-titles-container'>
                            <p className='profile-date'>Usuarios que la leyeron</p>
                            <span className='profile-notifications-number'>{metricas?.historialNovedadesMensual.lecturas ?? "-"}</span>
                            {/* <span className='profile-notifications-number'>-</span> */}                        
                        </div>
                    </div>
                </div>



                <div className="home-cards-container">
                    <div className='home-cards'>
                        <p className='home-cards-titles mx-3'>Última notificación enviada</p>
                        <hr className='profile-cards-divider' />
                        <div className="home-cards-divider">
                            <div className="home-title-container">
                                <div className='d-flex justify-content-between align-items-center | home-cards-titles-container'>
                                    <p className='profile-date'>Título</p>
                                    <span>{truncateText(metricas?.ultimaNotificacion?.title ?? "-", 16)}</span>                                 </div>
                                <div className='d-flex justify-content-between align-items-center | home-cards-titles-container'>
                                    <p className='profile-date'>Fecha</p>
                                    <span>{metricas?.ultimaNotificacion ? new Date(metricas.ultimaNotificacion?.createdAt).toLocaleDateString() : "-"}</span>
                                </div>
                            </div>
                            <div className="home-icon-container" onClick={openNotificacionModal}>
                                <EyeTemporaryIcon />
                            </div>
                        </div>
                    </div>
                    <div className='home-cards'>
                        <p className='home-cards-titles mx-3'>Última novedad publicada</p>
                        <hr className='profile-cards-divider' />
                        <div className="home-cards-divider">
                            <div className="home-title-container">
                                <div className='d-flex justify-content-between align-items-center | home-cards-titles-container'>
                                    <p className='profile-date'>Título</p>
                                    <span>{truncateText(metricas?.ultimaNovedad?.title ?? "-", 16)}</span>                                        </div>
                                <div className='d-flex justify-content-between align-items-center | home-cards-titles-container'>
                                    <p className='profile-date'>Fecha</p>
                                    <span>{metricas?.ultimaNovedad ? new Date(metricas.ultimaNovedad?.createdAt).toLocaleDateString() : "-"}</span>
                                </div>
                            </div>
                            <div className="home-icon-container" onClick={openNovedadModal}>
                                <EyeTemporaryIcon />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <DetailModalNews
                id={selectedNovedadId}
                showModal={showModalNovedades}
                handleCloseModal={closeModalNovedades}
            />

            <DetailModal
                id={selectedNotificacionId}
                showModal={showModalNotificaciones}
                handleCloseModal={closeModalNotificaciones}
            />
        </section>
    );
};

export default Home;
