import { JwtPayload } from "interfaces/jwtpayload";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { useEffect } from "react";
import { Container, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { PUBLIC_ROUTE } from "../../routes/publicRoutes";
import { login } from "../../store/slices/authSlice";
import { Navbar } from "./components/Navbar/Navbar";
import "./header.css";
import { RootState } from "store/store";
import { PRIVATE_ROUTE } from "routes/privateRoutes";

export const Header = () => {
  const dispatch = useDispatch();
  const { isLogged } = useSelector((state: RootState) => state.authData);
  const logoHeader = "/assets/img/logo-header.svg";

  // useEffect(() => {
  //   let grillasCookie = Cookies.get("notify");
  //   if (grillasCookie) {
  //     const { accessToken, refreshToken, instituciones } = JSON.parse(grillasCookie)
  //     const { user }: JwtPayload = jwtDecode(accessToken);
  //     dispatch(login({ accessToken, user, refreshToken }));
  //   } else {
  //     dispatch(login({}));
  //   }
  // }, [dispatch]);

  return (
    <header className="container-fluid fixed-top">
      <Container fluid className="header-container">
        {isLogged ? (
          <>
            <div className="header-logo-container">
              <Link to={PRIVATE_ROUTE.INICIO} className="text-dark">
                <Image
                  src={logoHeader}
                  alt="Logo Vamos Buenos Aires"
                  className="header-logo"
                />{" "}
                Backoffice miEscuela Familias
              </Link>
            </div>
            <Navbar></Navbar>
          </>
        ) : (
          <>
          <div className="d-flex">
          <Image
            src={logoHeader}
            alt="Logo Vamos Buenos Aires"
            className="header-logo"
          />
          <h1 className="header-title">Backoffice miEscuela Familias</h1>
          </div>
          </>
        )}
      </Container>
    </header>
  );
};
