import { NotificacionI } from 'interfaces/Notificacion';
import { useState } from 'react';
import { Table } from 'react-bootstrap';
import { DetailModal } from '../components/DetailModal/DetailModal';
import { NotificacionesRow } from './NotificacionesRow';

interface Props {
    elementos: NotificacionI[],
    handleDelete: (id: number | string) => void,
    handleEdit: (notificacion: NotificacionI) => void,
    startLoading: () => void,
    stopLoading: () => void,
}

export const NotificacionesTable = ({
    elementos = [],
    handleDelete,
    handleEdit,
    startLoading,
    stopLoading,
}: Props) => {
    const [selectedNotificacionId, setSelectedNotificacionId] = useState<null | number | string>(null)

    const [showDetailModal, setShowDetailModal] = useState(false)

    const handleShowDetailModal = (id: number | string) => {
        setSelectedNotificacionId(id)
        setShowDetailModal(true)
    }

    const handleCloseModal = () => {
        setShowDetailModal(false)
    }

    return (
        <>
            <Table striped bordered className='table-container'>
                <thead>
                    <tr className='table-heading'>
                        <th>Título</th>
                        <th>Tipo de notificación</th>
                        <th>Fecha de envio</th>
                        <th>Estado</th>
                        <th>Estado Reporte</th>
                        <th className='text-center'>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {elementos.map(ctEl =>
                        <NotificacionesRow
                            notificacion={ctEl}
                            handleShowDetailModal={handleShowDetailModal}
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                            startLoading={startLoading}   // Pasar estas funciones a NotificacionesRow
                            stopLoading={stopLoading}  
                        />)}
                </tbody>
            </Table>
            {
                <DetailModal
                    id={selectedNotificacionId}
                    handleCloseModal={handleCloseModal}
                    showModal={showDetailModal}
                />
            }
        </>
    )
}
