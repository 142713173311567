export const ENDPOINTS = {
    SCHOOL_EX: "/school-ex",
    SCHOOLS: "/schools",
    SCHOOL_LEVELS: "/school-levels",
    SCHOOL_YEARS: "/school-years",
    NOTIFICATION_TYPES: "/notification-types",
    NOTIFICATION_STATES: "/notification-states",
    NOTIFICATION_DELETE: "/notifications/",
    NOTIFICATIONS_BY_USER_ID: "/notifications/user/",
    NOTIFICATIONS: "/notifications",
    USERS: "/users/all",
    USER_BY_ID:"/users/",
    USER_CREATE: "/users",
    USER_DELETE:"/users/",
    USER_EDIT: "/users",
    ROLES: "/auth/roles",
    AREAS: "/areas",
    NEWS: "/news",
    NEWS_BY_ID: "/news/",
    NEWS_DELETE: "/news/",
    NEWS_CREATE:"/news",
    NEWS_EDIT: "/news",
    NEWS_STATES:"/news-states",
    NEWS_BY_USER_ID: "/news/user/",
    STATISTICS: "/statistics",
    STATISTICS_All: "/statistics/all",
    REPORTS_CREATE: "/create-reports/get-excel/",
    REPORTS_GENERATE: "/create-reports/generate",
    REPORTS_GET: "/create-reports/get-excel/",
}