import { showErrorToast, showSuccessToast } from 'components/CustomAlerts/CustomToast';
import { Input } from 'components/Input/Input';
import { Select } from 'components/Select/Select';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { PRIVATE_ROUTE } from 'routes/privateRoutes';
import { fetchNivelesEscuela } from 'store/slices/appSlice';
import { AppDispatch, RootState } from 'store/store';
import { getNovedadById, postNovedad, putNovedad } from 'services/novedades';
import { createNovedadesSchema } from 'schemas/novedades/createNovedadesSchema';
import { useAlertContext } from 'context/AlertContext';
import { SuccessIcon } from 'assets/icons/SuccessIcon';
import { ErrorIcon } from 'assets/icons/ErrorIcon'

interface Props {
    typeOfAction?: abmActionI;
}

export type abmActionI = "create" | "edit"

export const NovedadesABM = ({
    typeOfAction = "create",
}: Props) => {
    let { id } = useParams();
    const dispatch: AppDispatch = useDispatch();
    const { nivelesEscuela, loadingAppData } = useSelector((st: RootState) => st.appData)
    const { alerts, removeAlert, addAlert } = useAlertContext();
    const [isLoading, setIsLoading] = useState({
        form: false,
    })

    const handleReturn = () => {
        navigate(PRIVATE_ROUTE.NOVEDADES);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const handleSubmitCreate = async (values: { [key: string]: any }) => {
        try {
            
            const params = { ...values,
                link: values.link ? encodeURIComponent(values.link) : ""  };
            await postNovedad({ ...params });
            handleReturn();
            addAlert({ icon: <SuccessIcon/> , type: 'success', boldMessage: '¡La novedad fue creada con éxito!'});
            // showSuccessToast("¡La novedad fue creada con éxito!");
        } catch (err: any) {
            addAlert({ icon: <ErrorIcon/> , type: 'error', boldMessage: 'La novedad no pudo ser enviada.', normalMessage:"Por favor, intenta nuevamente en unos minutos."});
            // showErrorToast("La novedad no pudo ser enviada.", "Por favor, intenta nuevamente en unos minutos.");
        }
    };
    
    const handleSubmitEdit = async (values: { [key: string]: any }) => {
        try {
            const params = { ...values,
                link: values.link ? encodeURIComponent(values.link) : ""  };
            await putNovedad({ ...params, id });
            handleReturn();
            addAlert({ icon: <SuccessIcon/> , type: 'success', boldMessage: '¡La novedad fue editada y reprogramada con éxito!'});
            // showSuccessToast("¡La novedad fue editada y reprogramada con éxito!");
        } catch (err: any) {
            addAlert({ icon: <ErrorIcon/> , type: 'error', boldMessage: 'La novedad no pudo ser enviada.', normalMessage:"Por favor, intenta nuevamente en unos minutos."});
            // showErrorToast("La novedad no pudo ser enviada.", "Por favor, intenta nuevamente en unos minutos.");
        }
    };

    const navigate = useNavigate();

    interface novedadABMValuesI {
        novedad_titulo: string,
        novedad_subtitulo: string;
        novedad_descripcion: string;
        niveles_id: (number | string)[];
        link: string | null;
    }

    const defaultInitialValues: novedadABMValuesI = {
        niveles_id: [1,2,3],
        novedad_titulo: "",
        novedad_subtitulo: "",
        novedad_descripcion: "",
        link: "",
    }

    const { values,
        errors,
        touched,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit,
        setValues,
    } = useFormik({
        initialValues: defaultInitialValues,
        validationSchema: createNovedadesSchema,
        onSubmit: typeOfAction === "create" ? handleSubmitCreate : handleSubmitEdit,
    });

    const loadEditForm = async () => {
        try {
            setIsLoading(st => ({ ...st, form: true }));
            const errorLoadingNovedadResolve = () => {
                addAlert({ icon: <ErrorIcon/> , type: 'error', boldMessage: 'No fue posible cargar la novedad a editar'});
                // showErrorToast("No fue posible cargar la novedad a editar");
                navigate(PRIVATE_ROUTE.NOTIFICACIONES);
            };
    
            if (!id) return errorLoadingNovedadResolve();
    
            const novedad = await getNovedadById(id);
    
            if (!novedad) return errorLoadingNovedadResolve();
    
            const initialValues = {
                novedad_titulo: novedad.title,
                novedad_subtitulo: novedad.subtitle,
                novedad_descripcion: novedad.description || "",
                niveles_id: novedad.schoolLevels.map(level => level.id), 
                link: novedad.link || "", 
            };
    

            setValues(initialValues);
    
        } catch (err) {
        } finally {
            setIsLoading(st => ({ ...st, form: false }));
        }
    };
    
        useEffect(() => {
            if (typeOfAction === "edit") {
                loadEditForm();
            }
        
            if (!nivelesEscuela) {
                dispatch(fetchNivelesEscuela());
            }
        }, [typeOfAction, id, nivelesEscuela, dispatch]);

        useEffect(() => {
            if (nivelesEscuela && nivelesEscuela.length > 0) {
                setValues(prevValues => ({
                    ...prevValues,
                    niveles_id: nivelesEscuela.map(level => level.id), 
                }));
            }
        }, [nivelesEscuela, setValues]);


    return (<>
        {isSubmitting && (
            <div className="overlay">
                <Spinner animation="border" role="status" className="spinner-center" />
            </div>
        )}
        <section>
            <h1 className="section-title">
                {
                    typeOfAction == "edit" ?
                        "Editar notificación"
                        :
                        "Nueva notificación"
                }</h1>

            <h2 className="section-subtitle mt-2">Publicá una nueva novedad.</h2>

            <div>
                <div className="alert-container">
                    {alerts.map((alert, index) => (
                        <div key={index} className={`alert ${alert.type}`}>
                            <div>
                                {alert.icon}
                            </div>
                            <span className="alert-text">
                                <strong>{alert.boldMessage}</strong>
                                {alert.normalMessage && <span> {alert.normalMessage}</span>}
                            </span>            
                            {/* <button onClick={() => removeAlert(index)}>Cerrar</button> */}
                        </div>
                    ))}
                </div>
            </div>

            <Card>
                <form onSubmit={handleSubmit}>
                    <Row className="">
                        <Col sm={8} className="mb-3">
                            <Input
                                id="novedad_titulo"
                                onChange={handleChange}
                                values={values}
                                errors={errors}
                                touched={touched}
                                onBlur={handleBlur}
                                variant="gray-container"
                                title="Título de la novedad"
                                size="lg"
                                maxLength={40}
                                required
                            />
                        </Col>
                        <Col sm={8} className="mb-3">
                            <Input
                                id="novedad_subtitulo"
                                onChange={handleChange}
                                values={values}
                                errors={errors}
                                touched={touched}
                                onBlur={handleBlur}
                                variant="gray-container"
                                title="Subtítulo de la novedad"
                                size="lg"
                                maxLength={26}
                                required
                            />
                        </Col>
                        <Col sm={8} className="mb-3">
                            <Input
                                id="novedad_descripcion"
                                onChange={handleChange}
                                values={values}
                                errors={errors}
                                touched={touched}
                                onBlur={handleBlur}
                                variant="gray-container"
                                title="Descripción"
                                size="lg"
                                type='textarea'
                                maxLength={2000}
                                defaultHeight={"150px"}
                                required
                            />
                        </Col>
                    </Row>
                    <hr></hr>
                    <Row className="">
                        <Col sm={4} className="mb-3">
                            <Input
                                id="link"
                                onChange={handleChange}
                                values={values}
                                errors={errors}
                                touched={touched}
                                onBlur={handleBlur}
                                variant="gray-container"
                                title="Link"
                                size="lg"
                                type="text"
                                maxLength={255}
                                // required
                            />
                        </Col>
                        <Col sm={4} className="mb-3">
                        <Select
                            id="niveles_id"
                            title="Nivel"
                            size="lg"
                            optionsFormatConfig={{ value: "id", label: "name" }}
                            options={nivelesEscuela ?? []}
                            isLoading={loadingAppData.nivelesEscuela}
                            placeholder="Todos"
                            variant="gray-container"
                            required
                            values={values}
                            errors={errors}
                            touched={touched}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isMulti={true}
                            subtext={"Selección múltiple"}
                            withSelectAllOption
                            selectAllOptionLabel={"Todos"}
                        />
                        </Col>
                    </Row>
                    <hr></hr>
                    <Row>
                        <Col>
                            <div className="w-100 d-flex column-gap-3 justify-content-end">
                                <Button variant="secondary" size={"lg"} onClick={handleReturn}>Cancelar</Button>
                                <Button variant="primary" type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? "Publicando..." : "Publicar"}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </form>
            </Card>

        </section >
        </>
    )
}
