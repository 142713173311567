import React, { useEffect } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import "./userModal.css";
import { useFormik } from 'formik';
import { userSchema } from "../../../schemas/CreateUserSchema/userSchema";
import axios from 'axios';
import { Select } from 'components/Select/Select';
import { Input } from 'components/Input/Input';
import { AppDispatch, RootState } from 'store/store';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAreas, fetchRoles } from 'store/slices/appSlice';
import { postUsuario, editarUsuario } from 'services/usuarios';
import { toast } from 'react-toastify';
import { UsuarioI } from 'interfaces/Usuario';
import { idText } from 'typescript';
import { showErrorToast, showSuccessToast } from 'components/CustomAlerts/CustomToast';
import { useAlertContext } from 'context/AlertContext'
import { SuccessIcon } from 'assets/icons/SuccessIcon';
import { ErrorIcon } from 'assets/icons/ErrorIcon'

interface Props {
    showModal: boolean;
    handleCloseModal: () => void;
    refreshPage: () => void;
    usuario?: UsuarioI;
    isEditMode?: boolean;
}

export const UserModal = ({ handleCloseModal, showModal, refreshPage, usuario, isEditMode }: Props) => {
    const dispatch: AppDispatch = useDispatch();
    const { roles, areas, loadingAppData } = useSelector((st: RootState) => st.appData);
    const { alerts, removeAlert, addAlert } = useAlertContext();

    const onSubmit = async (values: any, actions: { resetForm: () => void; }) => {
        try {
            if (isEditMode) {
                await editarUsuario(values);
                addAlert({ icon: <SuccessIcon/> , type: 'success', boldMessage: '¡El usuario fue editado con éxito!'});
                // showSuccessToast("¡El usuario fue editado con éxito!");
            } else {
                await postUsuario(values);
                addAlert({ icon: <SuccessIcon/> , type: 'success', boldMessage: '¡El usuario fue agregado con éxito!'});
                // showSuccessToast("¡El usuario fue agregado con éxito!");
            }
            handleCloseModal();
            actions.resetForm();
            refreshPage();
        }
        catch (err: any) {
            addAlert({ icon: <ErrorIcon/> , type: 'error', boldMessage: 'El usuario no pudo ser agregado.', normalMessage:'Por favor, intenta nuevamente en unos minutos.'});
            // showErrorToast("El usuario no pudo ser agregado.", "Por favor, intenta nuevamente en unos minutos.");
            // console.log(err.message);
        }
    };

    const getIinitialValuesEdit = () => {
        return {
            id: usuario?.id,
            email: usuario?.email,
            name: usuario?.name,
            lastname: usuario?.lastname,
            roleId: usuario?.role.id,
            areaId: usuario?.area?.id,
        }
    }

    const {
        values,
        errors,
        touched,
        isSubmitting,
        handleSubmit,
        handleBlur,
        handleChange,
        resetForm,
    } = useFormik({
        initialValues: isEditMode ? getIinitialValuesEdit() : {
            email: "",
            name: "",
            lastname: "",
            roleId: "",
            areaId: "",
        },
        validationSchema: userSchema,
        onSubmit,
    });

    useEffect(() => {
        if (!roles) dispatch(fetchRoles());
        if (!areas) dispatch(fetchAreas());
    }, [dispatch, roles, areas]);

    return (
        <>
            <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>{isEditMode ? "Editar Usuario" : "Nuevo Usuario"}</Modal.Title>
                </Modal.Header>
                <form onSubmit={handleSubmit}>
                    <Modal.Body>
                        <Col>
                            <Row>
                                <Input
                                    id={"email"}
                                    title="Mail @bue.edu.ar"
                                    required
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    variant='gray-container'
                                />
                            </Row>
                            <Row className="mt-3">
                                <Col sm={6}>
                                    <Input
                                        id={"name"}
                                        title="Nombre"
                                        required
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        values={values}
                                        errors={errors}
                                        touched={touched}
                                        variant='gray-container'
                                    />
                                </Col>
                                <Col sm={6}>
                                    <Input
                                        id={"lastname"}
                                        title="Apellido"
                                        required
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        values={values}
                                        errors={errors}
                                        touched={touched}
                                        variant='gray-container'
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col sm={6}>
                                    <label htmlFor="">Área *</label>
                                    <Select
                                        id="areaId"
                                        optionsFormatConfig={{ value: "id", label: "name" }}
                                        options={areas ?? []}
                                        isLoading={loadingAppData.areas}
                                        onChange={handleChange}
                                        placeholder="Seleccionar"
                                        width="100%"
                                        variant="gray-container"
                                        size="lg"
                                        onBlur={handleBlur}
                                        values={values}
                                        errors={errors}
                                        touched={touched}

                                    />
                                </Col>
                                <Col sm={6}>
                                    <label htmlFor="">Rol *</label>
                                    <Select
                                        id="roleId"
                                        optionsFormatConfig={{ value: "id", label: "name" }}
                                        options={roles ?? []}
                                        isLoading={loadingAppData.roles}
                                        onChange={handleChange}
                                        placeholder="Seleccionar"
                                        width="100%"
                                        variant="gray-container"
                                        size="lg"
                                        onBlur={handleBlur}
                                        values={values}
                                        errors={errors}
                                        touched={touched}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="mx-3" variant="secondary" size="lg" onClick={handleCloseModal}>
                            Cancelar
                        </Button>
                        <Button variant="primary" size="lg" className="user-submit-button" disabled={isSubmitting} type="submit">
                            {isEditMode ? "Guardar Cambios" : "Confirmar"}
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
}
