import axios from "axios";

const BASE_URL = process.env.REACT_APP_API;

if (!BASE_URL) {
  const error_message = `In Axios setup (request.service.ts), BASE_URL has no value! Currently, the value to the app is ${BASE_URL}. Maybe you forgot to set the environment variable REACT_APP_API`;

  console.error(error_message);
  throw new Error(error_message);
}

export const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "Refresh-Sent": "false",
  },
});

interface SendRequestP {
  method: "POST" | "GET" | "PATCH" | "DELETE" | "PUT" | "GET_FILE";
  uri: string;
  params?: { [key: string]: string } | object;
  signal?: AbortSignal;
}

export async function sendRequest<T>({
  method,
  uri,
  params = {},
  signal,
}: SendRequestP): Promise<T> {
  switch (method) {
    case "POST": {
      const response: T = await axiosInstance.post(uri, params, { signal });
      return response;
    }

    case "GET": {
      const response: T = await axiosInstance.get(uri, { params, signal });
      return response;
    }

    case "PATCH": {
      const response: T = await axiosInstance.patch(uri, params, { signal });
      return response;
    }

    case "DELETE": {
      return await axiosInstance.delete(uri, { data: params });
    }

    case "PUT": {
      const response: T = await axiosInstance.put(uri, params, { signal });
      return response;
    }

    case "GET_FILE": {
      const response: T = await axios({
        method: "get",
        url: BASE_URL?.slice(0, -1) + uri,
        responseType: "blob",
        // headers: authHeader(),
      });

      return response;
    }

    default: {
      throw new Error("Falta method del fetch");
    }
  }
}
